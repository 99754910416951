import React, { useMemo, useState } from 'react';
import { LorawanActionTypes } from '@utils/deviceActions';
import rolesConstants from '@utils/rolesConstants';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import useSwr from '@src/hooks/useSwr';

const useActivation = ({ devices }) => {
  const { t } = useTranslation('lorawan');
  const [contractId, setContractId] = useState();

  const { data: contractsData } = useSwr('/service-proxy/contracts');

  const contracts = useMemo(
    () =>
      contractsData &&
      contractsData?.map(({ assetNumber, planName, nickname }) => ({
        value: assetNumber,
        label: `${assetNumber}${
          nickname ? ` - ${nickname}` : ''
        } - ${planName}`,
      })),
    [contractsData],
  );

  const steps = useMemo(
    () => [
      {
        title: t('contract'),
        name: 'contract',
        showWhenUpload: true,
        content: (
          <Select
            loading={!contracts}
            optionFilterProp="label"
            options={contracts}
            value={contractId}
            allowClear
            showSearch
            onChange={value => setContractId(value)}
          />
        ),
      },
    ],
    [contractId, contracts, t],
  );

  const payloadToSend = useMemo(
    () => ({
      action: LorawanActionTypes.ACTIVATION,
      contractId,
      devices,
    }),
    [contractId, devices],
  );

  return {
    steps,
    uploadPosition: 1,
    title: t(
      `device-actions:labels.lorawan.${rolesConstants.LORAWAN_ACTIVATION}`,
    ),
    payloadToSend,
    totalDevices: devices?.length,
  };
};

export default useActivation;
